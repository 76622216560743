import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideMatDrawerService {

  public quotOrdDetailDrawer!: any;
  public quotOrdId!: EventEmitter<any>;

  constructor() {
    this.quotOrdId = new EventEmitter<any>();
  }

  openQuotOrdDetailDrawer() {
    this.quotOrdDetailDrawer.open();
  }

  closeQuotOrdDetailDrawer() {
    this.quotOrdDetailDrawer.close();
  }
}
