<ngx-spinner></ngx-spinner>
<!-- Page Heading -->
<div class="d-sm-flex page-header-sm align-items-center justify-content-between mb-4">
    <h1 class="h3 h3-sm mb-0 text-gray-800">Order: {{orderDetails?.order_id}}</h1>
    <div>
        <a routerLink="/admin/generate-order"
           class="d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            Re-Order
        </a>
        <button class="d-sm-inline-block btn btn-sm btn-secondary shadow-sm ml-2"
                (click)="closeSideMatDrawer()">
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>

<div class="row">
    <!-- Area Chart -->
    <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="card shadow mb-4">
            <!-- Card Body -->
            <div class="card-body"
                 *ngIf="showMap"
                 style="padding: 0.5rem !important;">
                <div *ngIf="this.orderDetails.quotation.quotationdrops?.length > 1">
                    <agm-map #orderDetailMap
                             [latitude]="pickuplocation.lat"
                             [longitude]="pickuplocation.lng"
                             [zoom]="16">
                        <agm-direction [origin]="pickuplocation"
                                       [waypoints]="droplocations"
                                       [destination]="lastdroplocation"
                                       [travelMode]="DRIVING">
                        </agm-direction>
                        <agm-marker [latitude]="+this.orderDetails.driver_lat"
                                    [longitude]="+this.orderDetails.driver_lon"
                                    [iconUrl]="iconUrl"
                                    [renderOptions]="renderOptions">
                        </agm-marker>
                    </agm-map>
                </div>
                <div *ngIf="this.orderDetails.quotation.quotationdrops?.length == 1">
                    <agm-map #orderDetailMap
                             [latitude]="pickuplocation.lat"
                             [longitude]="pickuplocation.lng"
                             [zoom]="16">
                        <agm-direction [origin]="pickuplocation"
                                       [destination]="lastdroplocation"
                                       [travelMode]="DRIVING">
                        </agm-direction>
                        <agm-marker [latitude]="+this.orderDetails.driver_lat"
                                    [longitude]="+this.orderDetails.driver_lon"
                                    [iconUrl]="iconUrl"
                                    [renderOptions]="renderOptions">
                        </agm-marker>
                    </agm-map>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12">
        <div class="card shadow mb-4">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary col-md-5 col-sm-6">Order Info</h6>
            </div>
            <div class="card-body d-flex flex-direction-row justify-content-between"
                 style="flex-wrap: wrap;">
                <section style="display: flex; justify-content: start;">
                    <div style="flex: 0 0 auto !important; width: 130px;">
                        <div>Order Id</div>
                        <div>Order Status</div>
                        <div>Order Time</div>
                        <div>Pick-up Time</div>
                    </div>
                    <div style="flex: 0 0 auto !important; margin-right: 8px; margin-left: 2px;">
                        <div>:</div>
                        <div>:</div>
                        <div>:</div>
                        <div>:</div>
                    </div>
                    <div>
                        <div style="font-weight: 500;">{{orderDetails?.order_id}}</div>
                        <div style="font-weight: 500;">{{orderDetails?.order_status}}</div>
                        <div>{{orderDetails?.order_date | date: 'dd-MM-YYYY, h:mm a'}}</div>
                        <div *ngIf="orderDetails?.quotation.pickup_date">
                            {{orderDetails?.quotation.pickup_date | date: 'dd-MM-YYYY'}},
                            {{getFormattedTime(orderDetails?.quotation.pickup_time)}}
                        </div>
                    </div>
                </section>
                <section style="display: flex; justify-content: start;">
                    <div style="flex: 0 0 auto !important; width: 130px;">
                        <div>Booking Mode</div>
                        <div>Booking Type</div>
                        <div>Vehicle Type</div>
                        <div>Completed At</div>
                    </div>
                    <div style="flex: 0 0 auto !important; margin-right: 8px; margin-left: 2px;">
                        <div>:</div>
                        <div>:</div>
                        <div>:</div>
                        <div>:</div>
                    </div>
                    <div>
                        <div style="font-weight: 500;">
                            <span
                                  *ngIf="orderDetails?.quotation?.quotation_type!=null || orderDetails?.quotation?.quotation_type!=''">
                                {{orderDetails?.quotation?.quotation_type}}
                            </span>
                            <span
                                  *ngIf="orderDetails?.quotation?.quotation_type==null || orderDetails?.quotation?.quotation_type==''">
                                NA
                            </span>
                        </div>
                        <div style="font-weight: 500;">
                            <span *ngIf="orderDetails?.quotation?.instabooking=='yes'">Instant Booking</span>
                            <span *ngIf="orderDetails?.quotation?.instabooking=='no'">Future Booking</span>
                        </div>
                        <div>{{orderDetails?.quotation.vehicletype.name}}</div>
                        <div>
                            <span
                                  *ngIf="orderDetails?.completed_at != 'NA' && orderDetails?.completed_at!=null && orderDetails?.completed_at!=''">
                                {{orderDetails?.completed_at! | date: 'dd-MM-YYYY, h:mm a'}}
                            </span>
                            <span
                                  *ngIf="orderDetails?.completed_at == 'NA' || orderDetails?.completed_at==null || orderDetails?.completed_at==''">
                                NA
                            </span>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="card shadow mb-4"
             *ngIf="orderDetails?.driver_name && orderDetails?.driver_name!=''">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary col-md-5 col-sm-6">Driver Details</h6>
            </div>
            <div class="card-body">
                <div class="media mb-4">
                    <img *ngIf="orderDetails?.driver_photo===null || orderDetails?.driver_photo==''"
                         src="assets/img/blank_person_img.png"
                         style="height: 100px; border-radius: 4px;"
                         class="mr-3"
                         alt="...">
                    <img *ngIf="orderDetails?.driver_photo && orderDetails?.driver_photo!=''"
                         [src]="orderDetails?.driver_photo"
                         style="height: 100px; border-radius: 4px;"
                         class="mr-3"
                         alt="...">
                    <div class="media-body">
                        <h5 class="mt-0">{{orderDetails?.driver_name}}</h5>
                        <!-- <p style="margin-bottom: 2px !important">FBB8195J</p> -->
                        <p style="margin-bottom: 2px !important"
                           class="text-primary">
                            <a
                               href="https://api.whatsapp.com/send?phone={{checkCountryCodeExist(orderDetails?.driver_mobileno).replaceAll('+','')}}">
                                {{checkCountryCodeExist(orderDetails?.driver_mobileno)}}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card shadow mb-4">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary col-md-5 col-sm-6">Pricing</h6>
                <div class="dropdown no-arrow">
                    <a role="button"
                       id="editDropOff"
                       class="btn btn-sm btn-primary mr-3"
                       (click)="rebook(orderDetails?.id)">
                        Rebook
                    </a>
                </div>
            </div>
            <div class="card-body">
                <div class="media mb-2">
                    <div class="media-body">
                        <div class="d-flex mb-2">
                            <h5 style="margin-top: 4px; margin-bottom: 0px!important;">
                                Provider
                            </h5>
                            <img [src]="baseImageUrl+orderDetails?.serviceprovider?.logo"
                                 class="ml-2">
                        </div>
                        <div class="card-body"
                             style="background-color: rgba(14, 164, 225, .8); color: #fff; border-radius: 4px;">
                            <section style="display: flex; justify-content: start;">
                                <div style="flex: 0 0 auto !important; width: 130px;">
                                    <div>Vehicle</div>
                                    <div>Price</div>
                                    <div>Delivery ID</div>
                                </div>
                                <div style="flex: 0 0 auto !important; margin-right: 8px; margin-left: 2px;">
                                    <div>:</div>
                                    <div>:</div>
                                    <div>:</div>
                                </div>
                                <div>
                                    <div style="font-weight: 500;">
                                        <i *ngIf="orderDetails?.vehicle; else na"
                                           [ngClass]="getIcon(orderDetails?.vehicle)">
                                        </i>
                                        <ng-template #na>NA</ng-template>
                                    </div>
                                    <div style="font-weight: 500;">
                                        {{orderDetails?.delivery_price | currency}}
                                    </div>
                                    <div style="font-weight: 500;">
                                        <span *ngIf="orderDetails?.delivery_id!=null || orderDetails?.delivery_id!=''">
                                            {{orderDetails?.delivery_id}}
                                        </span>
                                        <span *ngIf="orderDetails?.delivery_id==null || orderDetails?.delivery_id==''">
                                            NA
                                        </span>
                                    </div>
                                </div>
                            </section>
                            <!-- <p style="margin-bottom: 4px !important">
                                {{orderDetails?.serviceprovider.name}}
                                <i *ngIf="orderDetails?.order_status == 'Completed'"
                                   class="ml-3"
                                   [ngClass]="getIcon(orderDetails?.vehicle)"></i>
                            </p>
                            <p *ngIf="orderDetails?.order_status == 'Completed'"
                               style="margin-bottom: 4px !important">
                                Price: <span style="margin-left: 57px;">{{orderDetails?.delivery_price |
                                    currency}}</span>
                            </p>
                            <p style="margin-bottom: 4px !important">
                                Delivery ID:
                                <span *ngIf="orderDetails?.delivery_id!=null || orderDetails?.delivery_id!=''"
                                      style="margin-left: 19px;">
                                    {{orderDetails?.delivery_id}}
                                </span>
                                <span *ngIf="orderDetails?.delivery_id==null || orderDetails?.delivery_id==''"
                                      style="margin-left: 19px;">
                                    NA
                                </span>
                            </p> -->
                            <p *ngIf="orderDetails?.serviceprovider.support_email!=null"
                               style="margin-bottom: 2px !important">
                                <i class="far fa-envelope"></i> &nbsp;
                                {{orderDetails?.serviceprovider.support_email}}
                            </p>
                            <p *ngIf="orderDetails?.serviceprovider.support_contact!=null"
                               style="margin-bottom: 8px !important">
                                <i class="fas fa-phone-alt"></i> &nbsp;
                                {{orderDetails?.serviceprovider.support_contact}}
                            </p>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let quotationsummary of orderDetails?.quotation.quotationsummary; let i=index">
                    <div *ngIf="orderDetails?.order_status == ''"
                         style="display: flex; justify-content: start; align-items: center;"
                         class="mb-2">
                        <div style="flex: 0 0 auto !important;"
                             [ngStyle]="{'font-weight': orderDetails?.service_provider_id==quotationsummary.service_provider && orderDetails?.vehicle?.toLowerCase()==quotationsummary.vehicle?.toLowerCase()? '500':'400', 'color': orderDetails?.service_provider_id==quotationsummary.service_provider && orderDetails?.vehicle?.toLowerCase()==quotationsummary.vehicle?.toLowerCase()? 'rgb(14, 164, 225)':'inherit'}">
                            <div style="width: 130px;">
                                <img [src]="baseImageUrl+quotationsummary?.serviceprovider?.logo"
                                     class="ml-2">
                                <!-- {{quotationsummary.serviceprovider.name}} -->
                            </div>
                        </div>
                        <div style="flex: 0 0 auto !important; margin-right: 2px; margin-left: 2px;"
                             [ngStyle]="{'font-weight': orderDetails?.service_provider_id==quotationsummary.service_provider && orderDetails?.vehicle?.toLowerCase()==quotationsummary.vehicle?.toLowerCase()? '500':'400', 'color': orderDetails?.service_provider_id==quotationsummary.service_provider && orderDetails?.vehicle?.toLowerCase()==quotationsummary.vehicle?.toLowerCase()? 'rgb(14, 164, 225)':'inherit'}">
                            <i style="font-size: 13px;"
                               [ngClass]="getIcon(quotationsummary?.vehicle)"></i>
                        </div>
                        <div style="flex: 0 0 auto !important; margin-right: 8px; margin-left: 2px;">
                            <div>:</div>
                        </div>
                        <div>
                            <div style="width: 75px;"
                                 [ngStyle]="{'font-weight': orderDetails?.service_provider_id==quotationsummary.service_provider && orderDetails?.vehicle?.toLowerCase()==quotationsummary.vehicle?.toLowerCase()? '500':'400', 'color': orderDetails?.service_provider_id==quotationsummary.service_provider && orderDetails?.vehicle?.toLowerCase()==quotationsummary.vehicle?.toLowerCase()? 'rgb(14, 164, 225)':'#000'}">
                                {{quotationsummary.price | currency}}
                            </div>
                        </div>
                        <span class="mobile-ellipsis-text"
                              [matTooltip]="getServiceProviderRemark(quotationsummary?.service_provider, quotationsummary?.vehicle)">
                            {{getServiceProviderRemark(quotationsummary?.service_provider, quotationsummary?.vehicle)}}
                        </span>
                        <!-- <div>
                            <div class="mobile-ellipsis-text"
                                 [matTooltip]="quotationsummary.remark"
                                 [ngStyle]="{'font-weight': orderDetails?.service_provider_id==quotationsummary.service_provider && orderDetails?.vehicle?.toLowerCase()==quotationsummary.vehicle?.toLowerCase()? '500':'400', 'color': orderDetails?.service_provider_id==quotationsummary.service_provider && orderDetails?.vehicle?.toLowerCase()==quotationsummary.vehicle?.toLowerCase()? 'rgb(14, 164, 225)':'#000'}">
                                {{quotationsummary.remark}}
                            </div>
                        </div> -->
                        <!-- <div style="flex: 0 0 auto !important;"
                             class="ml-2"
                             *ngIf="orderDetails?.service_provider_id!=quotationsummary.service_provider">
                            <button class="btn btn-primary btn-sm"
                                    (click)="directOrder(orderDetails?.quotation_id,quotationsummary.serviceprovider.id)">
                                Direct Order
                            </button>
                        </div> -->
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12">
        <div class="card shadow mb-4">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary col-md-5 col-sm-6">Details</h6>
            </div>
            <div class="card-body">
                <div class="media mb-4">
                    <span class="badge custom-badge custom-badge-md badge-secondary mr-3"> 1 </span>
                    <div class="media-body"
                         *ngIf="orderDetails?.quotation.pickupaddress.address!=null">
                        <h5 class="mt-0">PICK-UP</h5>
                        <p style="margin-bottom: 2px !important">{{orderDetails?.quotation.pickupaddress.address}}</p>
                        <p style="margin-bottom: 2px !important">Landmark:
                            {{orderDetails?.quotation.pickupaddress.landmark}}</p>
                        <small>{{orderDetails?.quotation.pickupaddress.contact_name +", "+
                            orderDetails?.quotation.pickupaddress.mobileno}}</small>
                    </div>
                </div>

                <div class="media mb-4"
                     *ngFor="let dropoff of orderDetails?.quotation.quotationdrops; let i=index">
                    <span class="badge custom-badge custom-badge-md badge-primary mr-3"> {{i+1}} </span>
                    <div class="media-body">
                        <h5 class="mt-0">DROP-OFF</h5>
                        <p style="margin-bottom: 2px !important">{{dropoff.dropupaddress.address}}</p>
                        <p style="margin-bottom: 2px !important">Landmark: {{dropoff.dropupaddress.landmark}}</p>
                        <small>{{dropoff.dropupaddress.contact_name+", "+dropoff.dropupaddress.mobileno}}</small>
                        <br>
                        <div class="btn-group btn-group-toggle grid-2-column-wrapper mt-2 mb-2"
                             style="max-width: 250px !important;"
                             data-toggle="buttons">
                            <label class="btn btn-sm btn-outline-primary btn-bordered"
                                   style="cursor: default;">
                                <input type="radio"
                                       name="options"
                                       id="option1">
                                {{orderDetails?.quotation.parcelsize.name}}
                            </label>
                            <label class="btn btn-sm btn-outline-primary btn-bordered"
                                   style="cursor: default;">
                                <input type="radio"
                                       name="options"
                                       id="option2">
                                {{orderDetails?.quotation.good.name}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="media mb-4"
                     *ngIf="orderDetails?.quotation.quotationdrops[0].packet_description">
                    <span class="badge custom-badge custom-badge-md badge-warning mr-3">
                        <i class="far fa-clipboard"></i>
                    </span>
                    <div class="media-body">
                        <h5 class="mt-0">NOTES TO DRIVER</h5>
                        <small>{{orderDetails?.quotation.quotationdrops[0].packet_description}}</small>
                    </div>
                </div>

                <section class="mb-4 px-5"
                         *ngIf="orderDetails?.pod && orderDetails?.pod!=''">
                    <div class="card p-3">
                        <h5 class="text-primary"><i class="fas fa-image"></i> &nbsp; POD</h5>
                        <img [src]="orderDetails?.pod"
                             class="card-img-top px-5">
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div class="position-fixed top-0 right-0 p-3"
     style="z-index: 10; right: 0px; top: 60px;">
    <div id="successToast"
         class="toast hide bg-success text-white"
         role="alert"
         aria-live="assertive"
         aria-atomic="true"
         data-delay="5000">
        <div class="toast-body">
            <span>{{successMsg}}</span>
        </div>
    </div>
</div>

<div class="position-fixed top-0 right-0 p-3"
     style="z-index: 10; right: 0px; top: 60px;">
    <div id="errorToast"
         class="toast hide bg-danger text-white"
         role="alert"
         aria-live="assertive"
         aria-atomic="true"
         data-delay="5000">
        <div class="toast-body">
            <span>{{errorMsg}}</span>
        </div>
    </div>
</div>