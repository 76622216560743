import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SideMatDrawerService } from 'src/app/shared/side-mat-drawer.service';

declare let $: any;

@Component({
  selector: 'app-admin-master-page',
  templateUrl: './admin-master-page.component.html',
  styleUrls: ['./admin-master-page.component.css']
})
export class AdminMasterPageComponent implements OnInit {

  loggedinUserName: string = "";
  loggedinUserProfilePath: string = "";
  @ViewChild('refreshSesionModal') refreshSesionModal!: NgbModalRef;
  @ViewChild('quotOrdDetailDrawer') quotOrdDetailDrawer!: MatDrawer;

  public isDetail = false;
  public isOrder!: boolean;
  public OrdId!: number;
  public QuotId!: number;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private config: NgbModalConfig,
    public sideMatDrawerSvc: SideMatDrawerService
  ) { }

  ngOnInit(): void {
    //if ($(window).width() < 480 && !$(".sidebar").hasClass("toggled")) {
    $("body").addClass("sidebar-toggled");
    $(".sidebar").addClass("toggled");
    $('.sidebar .collapse').collapse('hide');
    //};
    this.loggedinUserName = sessionStorage.getItem("userName") || "New User";
    this.loggedinUserProfilePath = sessionStorage.getItem("profilePhotoPath") && sessionStorage.getItem("profilePhotoPath") == 'null' ? "assets/img/undraw_profile.svg" : sessionStorage.getItem("profilePhotoPath")!;

    this.sideMatDrawerSvc.quotOrdId.subscribe(res => {
      this.isDetail = true;
      console.log("------------------------------------------", res);
      if (res.OrderId) {
        this.isOrder = true;
        this.OrdId = res.OrderId;
      } else {
        this.isOrder = false;
        this.QuotId = res.QuotId;
      }
    })
  }

  ngAfterViewInit() {
    this.sideMatDrawerSvc.quotOrdDetailDrawer = this.quotOrdDetailDrawer;

  }

  closeLeftMenu() {
    //if ($(window).width() < 480 && !$(".sidebar").hasClass("toggled")) {
    $("body").addClass("sidebar-toggled");
    $(".sidebar").addClass("toggled");
    $('.sidebar .collapse').collapse('hide');
    //};
  }

  sidebarToggle() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $('.sidebar .collapse').collapse('hide');
    };
  }

  onActivate(event: any) {
    // this.modalService.open(this.refreshSesionModal);
    // this.authService.tokenExpired.subscribe(value => {
    //   if (value == true) {
    //     
    //   }
    // })
    window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }

  refreshSesion() {

  }

  onLogout() {
    if (sessionStorage.getItem("token")) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("loggedIn");
      sessionStorage.clear();
      this.router.navigate(["/"]);
    }
  }


}
