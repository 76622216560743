<!-- Page Wrapper -->
<div id="wrapper">

    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar">

        <!-- Sidebar - Brand -->
        <li class="d-flex align-items-center justify-content-center">
            <a class="sidebar-brand "
               routerLink="/">
                <!-- <div class="sidebar-brand-icon rotate-n-15">
                <i class="fas fa-truck-moving"></i>
            </div> -->
                <div class="sidebar-brand-text mx-3">Delivery Master</div>
            </a>
            <i class="fa fa-times text-white d-md-none d-lg-none d-xl-none"
               (click)="closeLeftMenu()"></i>
        </li>
        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        <li class="nav-item active">
            <a class="nav-link admin"
               routerLink="/admin/dashboard"
               (click)="closeLeftMenu()">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span></a>
        </li>
        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Wallet -->
        <li class="nav-item active">
            <a class="nav-link admin"
               routerLink="/admin/wallet"
               (click)="closeLeftMenu()">
                <i class="fas fa-fw fa-wallet"></i>
                <span>Wallet</span>
                <!-- <span class="ml-4 badge badge-pill bg-danger">
                    $ 99
                </span> -->
            </a>
        </li>

        <!-- Divider -->
        <!-- <hr class="sidebar-divider">

        <li class="nav-item active">
            <a class="nav-link admin"
               routerLink="/admin/user-settings"
               (click)="closeLeftMenu()">
                <i class="fas fa-fw fa-cogs"></i>
                <span>Settings</span></a>
        </li> -->

        <!-- Divider -->
        <hr class="sidebar-divider">

        <li class="nav-item active">
            <a class="nav-link admin"
               routerLink="/admin/set-serviceprovider"
               (click)="closeLeftMenu()">
                <i class="fas fa-fw fa-hands-helping"></i>
                <span>Service Providers</span></a>
        </li>

        <hr class="sidebar-divider">

        <li class="nav-item active">
            <a class="nav-link admin"
               routerLink="/admin/addressbook"
               (click)="closeLeftMenu()">
                <i class="fas fa-fw fa-address-book"></i>
                <span>Address Book</span></a>
        </li>

        <hr class="sidebar-divider">

        <!-- <li class="nav-item active">
            <a class="nav-link admin"
               routerLink="/admin/dashboard"
               (click)="closeLeftMenu()">
                <i class="fas fa-fw fa-headset"></i>
                <span>Contact Us</span></a>
        </li> -->

        <hr class="sidebar-divider">

        <li class="nav-item active">
            <a class="nav-link admin"
               routerLink="/admin/faq"
               (click)="closeLeftMenu()">
                <i class="fas fa-fw fa-question-circle"></i>
                <span>FAQs</span></a>
        </li>

        <hr class="sidebar-divider">

        <!-- Heading -->
        <!-- <div class="sidebar-heading">
            Interface
        </div> -->

        <!-- Nav Item - Pages Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed"
               href="#"
               data-toggle="collapse"
               data-target="#collapseTwo"
               aria-expanded="true"
               aria-controls="collapseTwo">
                <i class="fas fa-fw fa-cog"></i>
                <span>Components</span>
            </a>
            <div id="collapseTwo"
                 class="collapse"
                 aria-labelledby="headingTwo"
                 data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Custom Components:</h6>
                    <a class="collapse-item"
                       href="buttons.html">Buttons</a>
                    <a class="collapse-item"
                       href="cards.html">Cards</a>
                </div>
            </div>
        </li> -->

        <!-- Sidebar Toggler (Sidebar) -->
        <!-- <div class="sidebar-toggle-btn text-center d-none d-md-inline">
            <button class="rounded-circle border-0"
                    id="sidebarToggle"
                    (click)="sidebarToggle()"></button>
        </div> -->
    </ul>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper"
         class="d-flex flex-column">



        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

            <!-- Sidebar Toggle (Topbar) -->
            <button id="sidebarToggleTop"
                    class="btn btn-link rounded-circle mr-3"
                    (click)="sidebarToggle()">
                <i class="fa fa-bars"></i>
            </button>

            <!-- Topbar Search -->
            <!-- <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                    <div class="input-group">
                        <input type="text"
                               class="form-control bg-light border-0 small"
                               placeholder="Search for..."
                               aria-label="Search"
                               aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-primary"
                                    type="button">
                                <i class="fas fa-search fa-sm"></i>
                            </button>
                        </div>
                    </div>
                </form> -->

            <!-- Topbar Navbar -->
            <ul class="navbar-nav">

                <!-- Nav Item - Search Dropdown (Visible Only XS) -->
                <li class="nav-item dropdown no-arrow d-sm-none">
                    <a class="sidebar-brand-sm nav-link dropdown-toggle text-primary"
                       routerLink="/">
                        Delivery Master
                    </a>

                </li>
            </ul>

            <ul class="navbar-nav ml-auto">

                <div class="topbar-divider d-none d-sm-block"></div>

                <!-- Nav Item - User Information -->
                <li class="nav-item dropdown no-arrow">
                    <a class="nav-link dropdown-toggle"
                       href="#"
                       id="userDropdown"
                       role="button"
                       data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false">
                        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Hi! {{loggedinUserName}}</span>
                        <img class="img-profile rounded-circle"
                             [src]="loggedinUserProfilePath">
                    </a>

                    <!-- Dropdown - User Information -->
                    <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                         aria-labelledby="userDropdown">
                        <a class="dropdown-item"
                           routerLink="/admin/user-profile">
                            <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Profile
                        </a>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item"
                                (click)="onLogout()"
                                data-toggle="modal"
                                data-target="#logoutModal">
                            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                            Logout
                        </button>
                    </div>
                </li>

            </ul>

        </nav>

        <mat-drawer-container style="min-height: 100vh;">
            <mat-drawer #quotOrdDetailDrawer
                        mode="over"
                        position="end"
                        opened="false"
                        style="top: 4.375rem; width: 800px!important;">

                <ng-container *ngIf="isDetail">
                    <app-order-details *ngIf="isOrder" [OrdId]="OrdId"></app-order-details>
                    <app-quotation-details *ngIf="!isOrder" [QuotId]="QuotId"></app-quotation-details>
                </ng-container>

            </mat-drawer>

            <!-- Main Content -->
            <div id="content">

                <!-- Topbar -->

                <!-- End of Topbar -->

                <!-- Begin Page Content -->
                <div class="container-fluid">

                    <router-outlet (activate)="onActivate($event)"></router-outlet>

                </div>
                <!-- /.container-fluid -->

            </div>
            <!-- End of Main Content -->



            <!-- Footer -->
            <!-- <footer class="sticky-footer bg-white">
            <div class="container my-auto">
                <div class="copyright text-center my-auto">
                    <span>Copyright &copy; Your Website 2021</span>
                </div>
            </div>
        </footer> -->
            <!-- End of Footer -->
        </mat-drawer-container>
    </div>
    <!-- End of Content Wrapper -->



</div>

<ng-template #refreshSesionModal
             let-c="close"
             let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title"
            id="modal-basic-title">Refresh</h4>
        <button type="button"
                style="outline:none;"
                class="close"
                aria-label="Close"
                (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Your session has expired, click on Refresh to continue.
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-primary"
                (click)="refreshSesion()">Refresh</button>
    </div>
</ng-template>
<!-- End of Page Wrapper -->

<!-- Scroll to Top Button-->
<!-- <a class="scroll-to-top rounded"
   href="#wrapper">
    <i class="fas fa-angle-up"></i>
</a> -->