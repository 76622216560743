import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailsService {

  baseUrl: string = environment.baseAPIUrl;

  constructor(
    private http: HttpClient
  ) { }

  getOrderDetails(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + "orders/" + id);
  }

  getQuotationDetails(id: number) {
    let payload = { "quotation_id": id };
    return this.http.post<any>(this.baseUrl + "quotation/temporder", payload);
  }

  directOrder(quotationId: number, spId: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + "order/skip-queue", { "quotation_id": quotationId, "service_provider_id": spId });
  }

  addTip(id: number, amount: number) {
    let payload = { "quotation_id": id, "tips": amount }
    return this.http.post<any>(this.baseUrl + "order/add-tips", payload);
  }

  setServiceProvider(quotationType:string, quotationSummaryIds: any[]) {
    let payload = {"quotation_type":quotationType, "quotation_summary_ids": quotationSummaryIds.toString() }
    return this.http.post<any>(this.baseUrl + "quotation/setserviceprovider", payload);
  }

  voidQuoatation(quotId: number) {
    let payload = { "quotation_id": quotId }
    return this.http.post<any[]>(this.baseUrl + "quotation/void", payload);
  }

  getUserServiceProviderSettings(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + "user-serviceprovider-setting");
  }

}
