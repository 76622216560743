<ngx-spinner name="quotDetailSpinner"></ngx-spinner>
<div class="d-sm-flex page-header-sm align-items-center justify-content-between mb-4">
    <h1 class="h3 h3-sm mb-0 text-gray-800">Quotation: {{quotationDetails?.quot_id}}</h1>
    <button *ngIf="isSlideView"
            class="d-sm-inline-block btn btn-sm btn-secondary shadow-sm ml-2"
            (click)="closeSideMatDrawer()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="card shadow mb-4">
            <div class="card-body"
                 *ngIf="showMap"
                 style="padding: 0.5rem !important;">
                <div *ngIf="this.quotationDetails?.quotationdrops?.length > 1">
                    <agm-map #orderDetailMap
                             [latitude]="pickuplocation.lat"
                             [longitude]="pickuplocation.lng"
                             [zoom]="16">
                        <agm-direction [origin]="pickuplocation"
                                       [waypoints]="droplocations"
                                       [destination]="lastdroplocation"
                                       [travelMode]="DRIVING">
                        </agm-direction>
                    </agm-map>
                </div>
                <div *ngIf="this.quotationDetails?.quotationdrops?.length == 1">
                    <agm-map #orderDetailMap
                             [latitude]="pickuplocation.lat"
                             [longitude]="pickuplocation.lng"
                             [zoom]="16">
                        <agm-direction [origin]="pickuplocation"
                                       [destination]="lastdroplocation"
                                       [travelMode]="DRIVING">
                        </agm-direction>
                    </agm-map>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12">
        <div class="card shadow mb-4">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary col-md-5 col-sm-6">Quotation Info</h6>
            </div>
            <div class="card-body d-flex flex-direction-row justify-content-between wrap"
                 style="flex-wrap: wrap;">
                <section style="display: flex; justify-content: start;">
                    <div style="flex: 0 0 auto !important; width: 130px;">
                        <div>Quotation Id</div>
                        <div>Quotation Status</div>
                        <div>Pick-up Time</div>
                    </div>
                    <div style="flex: 0 0 auto !important; margin-right: 8px; margin-left: 2px;">
                        <div>:</div>
                        <div>:</div>
                        <div>:</div>
                    </div>
                    <div>
                        <div style="font-weight: 500;">{{quotationDetails?.quot_id}}</div>
                        <div style="font-weight: 500;"
                             *ngIf="quotationDetails?.order_status!='Void'; else orderStatus">
                            {{quotationDetails?.order_status==2?'Pending':'Pending'}}
                        </div>
                        <ng-template #orderStatus>
                            <div style="font-weight: 500;">{{quotationDetails?.order_status}}</div>
                        </ng-template>
                        <div *ngIf="quotationDetails?.pickup_date">
                            {{quotationDetails?.pickup_date | date: 'dd-MM-YYYY'}},
                            {{getFormattedTime(quotationDetails?.pickup_time)}}
                        </div>
                    </div>
                </section>
                <section style="display: flex; justify-content: start;">
                    <div style="flex: 0 0 auto !important; width: 130px;">
                        <div>Booking Mode</div>
                        <div>Booking Type</div>
                        <div>Vehicle Type</div>
                    </div>
                    <div style="flex: 0 0 auto !important; margin-right: 8px; margin-left: 2px;">
                        <div>:</div>
                        <div>:</div>
                        <div>:</div>
                    </div>
                    <div>
                        <div style="font-weight: 500;">
                            <span
                                  *ngIf="quotationDetails?.quotation_type!=null || quotationDetails?.quotation_type!=''">
                                {{quotationDetails?.quotation_type}}
                            </span>
                            <span
                                  *ngIf="quotationDetails?.quotation_type==null || quotationDetails?.quotation_type==''">
                                NA
                            </span>
                        </div>
                        <div style="font-weight: 500;">
                            <span *ngIf="quotationDetails?.instabooking=='yes'">Instant Booking</span>
                            <span *ngIf="quotationDetails?.instabooking=='no'">Future Booking</span>
                        </div>
                        <div>{{quotationDetails?.vehicletype?.name}}</div>
                    </div>
                </section>
            </div>
        </div>

        <div class="card shadow mb-4"
             *ngIf="isServiceProviderSelected">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary col-md-5 col-sm-6">Pricing</h6>
                <div class="dropdown no-arrow">
                    <a role="button"
                       id="editDropOff"
                       class="btn btn-sm btn-primary mr-3"
                       (click)="openAddTipModal(addTipModal)">
                        Add Tip
                    </a>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngFor="let quotSummary of quotationDetails?.quotationsummary; let i=index">
                    <div style="display: flex; justify-content: start; align-items: center;"
                         class="mb-2">
                        <div style="flex: 0 0 auto !important;">
                            <div style="width: 105px;">
                                <img [src]="baseImageUrl+quotSummary?.serviceprovider?.logo"
                                     class="ml-2">
                            </div>
                        </div>
                        <div style="flex: 0 0 auto !important; margin-right: 5px; margin-left: 5px;">
                            <i style="font-size: 13px;"
                               [ngClass]="getIcon(quotSummary?.vehicle)"></i>
                        </div>
                        <div style="flex: 0 0 auto !important; margin-right: 8px; margin-left: 2px;">
                            <div>:</div>
                        </div>
                        <div>
                            <div style="width: 75px;">
                                {{quotSummary?.price | currency}}
                            </div>
                        </div>
                        <div style="flex: 0 0 auto !important; width: 70px;"
                             class="d-mobile-none">
                            <span *ngIf="quotSummary?.remark !=''"
                                  class="text-danger"
                                  [matTooltip]="quotSummary?.remark">
                                Error
                            </span>
                            <span *ngIf="quotSummary?.remark ==''">
                                {{getServiceProviderRemark(quotSummary?.service_provider, quotSummary?.vehicle)}}
                            </span>
                            <span *ngIf="quotSummary?.order_status==0">
                                Waiting...
                            </span>
                        </div>
                        <div style="flex: 0 0 auto !important; width: 96px; flex-wrap: wrap;"
                             class="d-mobile">
                            <span *ngIf="quotSummary?.remark !=''"
                                  class="text-danger"
                                  [matTooltip]="quotSummary?.remark">
                                Error
                            </span>
                            <span *ngIf="quotSummary?.remark ==''">
                                {{getServiceProviderRemark(quotSummary?.service_provider, quotSummary?.vehicle)}}
                            </span>
                            <span *ngIf="quotSummary?.order_status==0">
                                Waiting...
                            </span>
                        </div>
                        <div *ngIf="quotationDetails?.quotation_type?.toLowerCase() == 'cost saving' && quotSummary.order_status==0 && quotationDetails.order_status != 'Void'"
                             style="flex: 0 0 auto !important;">
                            <button class="btn btn-primary btn-sm d-mobile-none"
                                    (click)="directOrder(quotationDetails?.id,quotSummary?.service_provider)">
                                Skip
                            </button>
                            <button class="btn btn-primary btn-sm d-mobile"
                                    style="padding: 0.25rem 0.25rem !important;"
                                    (click)="directOrder(quotationDetails?.id,quotSummary?.service_provider)">
                                Skip
                            </button>
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>

        <div class="card shadow mb-4"
             *ngIf="!isServiceProviderSelected">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary col-md-5 col-sm-6">Pricing</h6>
                <!-- <div class="dropdown no-arrow">
                    <a role="button"
                       id="editDropOff"
                       class="btn btn-sm btn-primary mr-3"
                       (click)="openAddTipModal(addTipModal)">
                        Add Tip
                    </a>
                </div> -->
            </div>
            <div class="card-body">
                <ng-container *ngIf="quotationDetails?.is_quotation_complete=='1'; else spinnerTemplate">
                    <div *ngFor="let quotSummary of quotationDetails?.quotationsummary; let i=index"
                         style="display: flex; justify-content: start; align-items: center;">
                        <div style="flex: 0 0 auto !important;width: 180px; overflow-wrap: normal;">
                            <mat-checkbox color="primary"
                                          checked="true"
                                          (change)="selectServiceProviders($event.checked, quotSummary?.id)">
                                <img [src]="baseImageUrl+quotSummary?.serviceprovider?.logo"
                                     class="ml-2">
                                <i style="font-size: 15px; margin-left: 5px; margin-right: 5px;"
                                   [ngClass]="getIcon(quotSummary?.vehicle)"></i>
                            </mat-checkbox>
                        </div>

                        <div
                             style="flex: 0 0 auto !important; margin-right: 8px; margin-left: 2px; padding-bottom: 8px;">
                            <div>:</div>
                        </div>

                        <div style="flex: 0 0 auto !important;">
                            <div style="width: 75px; padding-bottom: 8px;">
                                {{quotSummary?.price | currency}}
                            </div>
                        </div>
                    </div>
                    <label>Select Mode</label>
                    <form [formGroup]="quotationForm">
                        <div class="btn-group btn-group-toggle grid-2-column-wrapper mb-3"
                             data-toggle="buttons"
                             style="width: 334px;">
                            <label *ngFor="let item of quotationTypes"
                                   class="btn btn-outline-primary btn-bordered"
                                   [ngClass]="{'active': item.id === quotationType.value}">
                                <input type="radio"
                                       name="quotationType"
                                       id="item.id"
                                       formControlName="quotationType"
                                       [value]="item.id"
                                       (change)="changeQuotationType(item.id)">
                                {{item.name}}
                            </label>
                        </div>
                    </form>
                </ng-container>
                <ng-template #spinnerTemplate>
                    <div style="display: flex; flex-direction: column;">
                        <!-- <mat-spinner [diameter]="40"></mat-spinner>Processing... -->

                        <div *ngFor="let sp of serviceProviders; let i=index"
                             style="display: flex; justify-content: start; align-items: center;"
                             class="mb-2">
                            <div style="flex: 0 0 auto !important;width: 130px; overflow-wrap: normal;">
                                <!-- <mat-checkbox color="primary"
                                              checked="true"
                                              (change)="selectServiceProviders($event.checked, quotSummary?.id)">
                                    {{quotSummary?.serviceprovider?.name}}
                                    <i style="font-size: 15px; margin-left: 5px;"
                                       [ngClass]="getIcon(quotSummary?.vehicle)"></i>
                                </mat-checkbox>-->
                                <img [src]="baseImageUrl+sp.serviceprovider.logo">
                                <!-- <i style="font-size: 15px; margin-left: 5px;"
                                   [ngClass]="getIcon(quotSummary?.vehicle)"></i> -->
                            </div>

                            <div style="flex: 0 0 auto !important; margin-right: 8px; margin-left: 2px;">
                                <div>:</div>
                            </div>

                            <div style="flex: 0 0 auto !important;">
                                <div style="width: 75px;">
                                    <img src="assets/img/827.gif">
                                    <!-- {{quotSummary?.price | currency}} -->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="card-footer">
                <button *ngIf="quotationDetails?.is_quotation_complete=='1'"
                        class="btn btn-primary btn-sm float-right"
                        [disabled]="quotation_summary_ids.length == 0"
                        (click)="completeOrder()">
                    Place Order
                </button>
                <button *ngIf="quotationDetails?.is_quotation_complete=='1'"
                        class="btn btn-primary btn-sm float-right mr-2"
                        [disabled]="quotation_summary_ids.length == 0"
                        (click)="voidQuotation()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12">
        <div class="card shadow mb-4">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary col-md-5 col-sm-6">Details</h6>
            </div>
            <div class="card-body">
                <div class="media mb-4">
                    <span class="badge custom-badge custom-badge-md badge-secondary mr-3"> 1 </span>
                    <div class="media-body"
                         *ngIf="quotationDetails?.pickupaddress.address!=null">
                        <h5 class="mt-0">PICK-UP</h5>
                        <p style="margin-bottom: 2px !important">{{quotationDetails?.pickupaddress.address}}</p>
                        <p style="margin-bottom: 2px !important">Landmark:
                            {{quotationDetails?.pickupaddress.landmark}}</p>
                        <small>{{quotationDetails?.pickupaddress.contact_name +", "+
                            quotationDetails?.pickupaddress.mobileno}}</small>
                    </div>
                </div>

                <div class="media mb-4"
                     *ngFor="let dropoff of quotationDetails?.quotationdrops; let i=index">
                    <span class="badge custom-badge custom-badge-md badge-primary mr-3"> {{i+1}} </span>
                    <div class="media-body">
                        <h5 class="mt-0">DROP-OFF</h5>
                        <p style="margin-bottom: 2px !important">{{dropoff?.dropupaddress?.address}}</p>
                        <p style="margin-bottom: 2px !important">Landmark: {{dropoff?.dropupaddress?.landmark}}</p>
                        <small>{{dropoff?.dropupaddress?.contact_name+",
                            "+dropoff?.dropupaddress?.mobileno}}</small>
                        <br>
                        <div class="btn-group btn-group-toggle grid-2-column-wrapper mt-2 mb-2"
                             style="max-width: 250px !important;"
                             data-toggle="buttons">
                            <label class="btn btn-sm btn-outline-primary btn-bordered"
                                   style="cursor: default;">
                                <input type="radio"
                                       name="options"
                                       id="option1">
                                {{quotationDetails?.parcelsize?.name}}
                            </label>
                            <label class="btn btn-sm btn-outline-primary btn-bordered"
                                   style="cursor: default;">
                                <input type="radio"
                                       name="options"
                                       id="option2">
                                {{quotationDetails?.good?.name}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="media mb-4"
                     *ngIf="quotationDetails?.quotationdrops[0].packet_description">
                    <span class="badge custom-badge custom-badge-md badge-warning mr-3">
                        <i class="far fa-clipboard"></i>
                    </span>
                    <div class="media-body">
                        <h5 class="mt-0">NOTES TO DRIVER</h5>
                        <small>{{quotationDetails?.quotationdrops[0].packet_description}}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #addTipModal
             let-c="close"
             let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title"
            id="modal-basic-title">Add Tip</h4>
        <button type="button"
                style="outline:none;"
                class="close"
                aria-label="Close"
                (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="addTipForm">
            <div class="form-group">
                <label>Amount </label>
                <input type="number"
                       formControlName="amountToBeAdded"
                       (keypress)="numberOnly($event)"
                       class="form-control">
                <small class="text-danger"
                       *ngIf="amountToBeAdded.invalid && (amountToBeAdded.dirty || amountToBeAdded.touched)">
                    Please enter a valid amount.
                </small>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-primary"
                (click)="addTip()">Proceed</button>
    </div>
</ng-template>

<div class="position-fixed top-0 right-0 p-3"
     style="z-index: 10; right: 0px; top: 60px;">
    <div id="successToast"
         class="toast hide bg-success text-white"
         role="alert"
         aria-live="assertive"
         aria-atomic="true"
         data-delay="5000">
        <div class="toast-body">
            <span>{{successMsg}}</span>
        </div>
    </div>
</div>

<div class="position-fixed top-0 right-0 p-3"
     style="z-index: 10; right: 0px; top: 60px;">
    <div id="errorToast"
         class="toast hide bg-danger text-white"
         role="alert"
         aria-live="assertive"
         aria-atomic="true"
         data-delay="5000">
        <div class="toast-body">
            <span>{{errorMsg}}</span>
        </div>
    </div>
</div>