import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderDetailsService } from '../services-and-models/order-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { geolocation, waypoint } from '../services-and-models/map-models';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SideMatDrawerService } from 'src/app/shared/side-mat-drawer.service';

declare var $: any;

@Component({
  selector: 'app-quotation-details',
  templateUrl: './quotation-details.component.html',
  styleUrls: ['./quotation-details.component.css'],
  providers: [OrderDetailsService]
})
export class QuotationDetailsComponent implements OnInit {
  @Input('QuotId') QuotId!: number;
  quotationId!: number;
  quotationDetails!: any;
  successMsg!: string;
  errorMsg!: string;
  showMap: boolean = false;
  pickuplocation: geolocation = new geolocation();
  droplocations: waypoint[] = [];
  lastdroplocation: geolocation = new geolocation();
  driverlocation: geolocation = new geolocation();

  @ViewChild('addTipModal') addTipModal!: NgbModalRef;

  addTipForm!: FormGroup;

  quotationTypes: any[] = [{ name: "Urgent", id: "URGENT" }, { name: "Cost Saving", id: "COST SAVING" }];
  quotation_summary_ids: any[] = [];
  serviceProviders: any[] = [];

  isQuotationComplete: boolean = false;
  isRefresh: boolean = true;
  isServiceProviderSelected: boolean = false;

  baseImageUrl = environment.baseImageUrl;
  isSlideView = true;

  get amountToBeAdded(): FormControl {
    return this.addTipForm.get("amountToBeAdded") as FormControl;
  }

  public renderOptions = {
    //suppressMarkers: true,
  }

  public markerOptions = {
    origin: {
      //icon: 'https://www.shareicon.net/data/32x32/2016/04/28/756617_face_512x512.png',
    },
    destination: {
      //icon: 'https://www.shareicon.net/data/32x32/2016/04/28/756626_face_512x512.png',
    },
    waypoints: {
      //icon: 'https://www.shareicon.net/data/32x32/2016/04/28/756626_face_512x512.png',
    }
  }

  quotationForm!: FormGroup;
  get quotationType(): FormControl {
    return this.quotationForm.get("quotationType") as FormControl;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderDetailService: OrderDetailsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private formbuilder: FormBuilder,
    private sideMatDrawerSvc: SideMatDrawerService
  ) { }

  ngOnInit(): void {
    this.spinner.show("quotDetailSpinner", {
      type: "ball-spin-clockwise-fade",
      color: '#0ea4e1'
    });

    this.addTipForm = this.formbuilder.group({
      amountToBeAdded: ["", [Validators.required]],
    })

    this.quotationForm = this.formbuilder.group({
      quotationType: ["COST SAVING", [Validators.required]]
    })

    if (this.QuotId) {
      this.quotationId = this.QuotId;
      this.sideMatDrawerSvc.quotOrdId.subscribe(res => {
        this.spinner.show("quotDetailSpinner", {
          type: "ball-spin-clockwise-fade",
          color: '#0ea4e1'
        });
        this.isSlideView = true;
        this.quotationId = res.QuotId;
        this.getServiceProviderList();
        this.getQuotationDetails();
      });
      this.getServiceProviderList();
        this.getQuotationDetails();
    } else {
      this.activatedRoute.params.subscribe(param => {
        this.isSlideView = false;
        this.quotationId = param['id'];
        this.getServiceProviderList();
        this.getQuotationDetails();
      })
    }
  }

  private getServiceProviderList() {
    this.orderDetailService.getUserServiceProviderSettings().subscribe((returnSPData: any) => {
      this.serviceProviders = [];
      if (returnSPData.status == true && returnSPData.data.length > 0) {
        returnSPData.data.forEach((sp: any) => {
          if (sp.is_enable == 1)
            this.serviceProviders.push(sp);
        });
      }
      console.log(this.serviceProviders)
    })
  }

  private getQuotationDetails() {
    this.orderDetailService.getQuotationDetails(this.quotationId).subscribe((returnData: any) => {
      console.log(returnData);
      this.quotationDetails = returnData.data;

      if (this.quotationDetails.is_quotation_complete == "1") {
        if (this.quotationDetails.order_status != 2) {
          this.isServiceProviderSelected = true;
        }
        this.isQuotationComplete = true;
        this.isRefresh = false;
      }
      this.quotation_summary_ids = [];
      this.quotationDetails.quotationsummary.forEach((quotSummary: any) => {
        this.quotation_summary_ids.push(quotSummary.id);
      });

      console.log(this.quotation_summary_ids);
      this.pickuplocation.lat = +this.quotationDetails?.pickupaddress.latitude;
      this.pickuplocation.lng = +this.quotationDetails?.pickupaddress.longitude;
      // this.quotationDetails?.quotationdrops.forEach((drop: any) => {
      //   this.droplocations.push({
      //     location: {
      //       lat: +drop.dropupaddress.latitude,
      //       lng: +drop.dropupaddress.longitude
      //     },
      //     stopover: true
      //   })
      // });
      // this.lastdroplocation.lat = +this.quotationDetails?.quotationdrops[this.quotationDetails?.quotationdrops?.length - 1]?.dropupaddress?.latitude;
      // this.lastdroplocation.lng = +this.quotationDetails?.quotationdrops[this.quotationDetails?.quotationdrops?.length - 1]?.dropupaddress?.longitude;
      //if (this.quotationDetails?.quotationdrops?.length > 1) {
      for (let index = 0; index < this.quotationDetails?.quotationdrops?.length - 1; index++) {
        const drop = this.quotationDetails?.quotationdrops[index];
        this.droplocations.push({
          location: {
            lat: +drop.dropupaddress.latitude,
            lng: +drop.dropupaddress.longitude
          },
          stopover: true
        });
      }
      // } else {
      //   this.droplocations.push({
      //     location: {
      //       lat: +this.quotationDetails?.quotationdrops[0]?.dropupaddress.latitude,
      //       lng: +this.quotationDetails?.quotationdrops[0]?.dropupaddress.longitude
      //     },
      //     stopover: true
      //   })
      // }
      let lastIndex = this.quotationDetails?.quotationdrops?.length - 1;
      //console.log(lastIndex)
      this.lastdroplocation.lat = +this.quotationDetails?.quotationdrops[lastIndex]?.dropupaddress?.latitude;
      this.lastdroplocation.lng = +this.quotationDetails?.quotationdrops[lastIndex]?.dropupaddress?.longitude;

      this.showMap = true;

      this.spinner.hide("quotDetailSpinner");
      if (this.isRefresh) {
        setTimeout(() => {
          this.getQuotationDetails();
        }, 20000);
      }
    });
  }

  selectServiceProviders(checked: any, quotationSummaryId: number) {
    console.log(checked);
    if (checked) {
      this.quotation_summary_ids.push(quotationSummaryId);
    } else {
      let existingIdIndex = this.quotation_summary_ids.findIndex(x => x == quotationSummaryId);
      if (existingIdIndex > -1) {
        this.quotation_summary_ids.splice(existingIdIndex, 1);
      }
    }

    console.log(this.quotation_summary_ids.toString());
  }

   changeQuotationType(quotationType: string) {
    this.quotationForm.get("quotationType")?.patchValue(quotationType);
  }

  completeOrder() {
    if (this.quotation_summary_ids.length > 0) {
      //console.log(this.quotationType.value);      
      this.orderDetailService.setServiceProvider(this.quotationType.value, this.quotation_summary_ids).subscribe(returnData => {
        console.log(returnData);
        if (returnData.success == true) {
          this.successMsg = returnData.message
          $('#successToast').toast('show');
          this.getQuotationDetails();
        }
      })
    }
  }

  voidQuotation() {
    let confirmation = confirm("Do you want to cancel this quotation?");
    if (confirmation) {
      this.orderDetailService.voidQuoatation(this.quotationId).subscribe((returnData: any) => {
        console.log(returnData);
        if (returnData.success == true) {
          this.getQuotationDetails();
          this.successMsg = returnData.message;
          $('#successToast').toast('show');
        } else {
          this.getQuotationDetails();
          this.errorMsg = returnData.message;
          $('#errorToast').toast('show');
        }
      })
    }
  }

  directOrder(quotationId: number, spId: number) {
    let confirmation = confirm("Do you want to skip queue?");

    if (confirmation) {
      this.orderDetailService.directOrder(quotationId, spId).subscribe((returnData: any) => {
        console.log(returnData);
        if (returnData.status == 1) {
          this.successMsg = returnData.message
          $('#successToast').toast('show');
          setTimeout(() => {
            window.location.reload();
          }, 100);
        } else {
          this.errorMsg = returnData.message
          $('#errorToast').toast('show');
        }
      })
    }
  }

  getServiceProviderRemark(spId: number, vehicle: string) {
    // let remark = { error: false, msg: '' };
    // let resRemark;
    // let spIdIndex;
    // if (this.quotationDetails.quotationsummary && this.quotationDetails.quotationsummary.length > 0) {
    //   spIdIndex = this.quotationDetails.quotationsummary.findIndex((x: any) => x.service_provider == spId);
    //   if (spIdIndex != -1) {
    //     resRemark = '';
    //     resRemark = this.quotationDetails.quotationsummary[spIdIndex].remark;
    //     if (resRemark != '') {
    //       remark = { error: true, msg: resRemark };
    //       return remark;
    //     } else if (this.quotationDetails.temporder && this.quotationDetails.temporder.length > 0) {
    //       spIdIndex = this.quotationDetails.temporder.findIndex((x: any) => x.service_provider_id == spId);
    //       if (spIdIndex != -1) {
    //         resRemark = '';
    //         resRemark = this.quotationDetails.temporder[spIdIndex].remark;
    //         if (resRemark != '') {
    //           remark = { error: false, msg: resRemark };
    //           return remark;
    //         }
    //       }
    //     }
    //   }
    // }
    if (this.quotationDetails.temporder && this.quotationDetails.temporder.length > 0) {
      let spIdIndex = this.quotationDetails.temporder.findIndex((x: any) => x.service_provider_id == spId && x.vehicle.toLowerCase().includes(vehicle.toLowerCase()));
      if (spIdIndex != -1) {
        let remark = this.quotationDetails.temporder[spIdIndex].remark;
        if (remark && remark != null) {
          return remark
        } else {
          return ""
        }
      } else {
        if (this.quotationDetails.order_status != "Void") {
          return ""
        } else {
          return "Qoutation is Void"
        }
      }
    } else {
      return "";
    }
  }

  interval!: any;

  // refreshData() {
  //   if (!this.isQuotationComplete) {
  //     this.interval = setInterval(() => {
  //       this.getQuotationDetails();
  //     }, 20000);
  //   }else{
  //     if (this.interval) {
  //       clearInterval(this.interval);
  //     }
  //   }
  // }

  getIcon(vehicle: string) {
    let vehicleName = vehicle.toLowerCase();
    let iconClass: string = '';
    if (vehicleName.includes("motorcycle") || vehicleName.includes("bike")) {
      iconClass = "fas fa-motorcycle";
    } else if (vehicleName.includes("car")) {
      iconClass = "fas fa-car-side";
    } else if (vehicleName.includes("truck")) {
      iconClass = "fas fa-truck";
    }

    return iconClass;
  }

  openAddTipModal(modal: any) {
    this.modalService.open(modal, { centered: true });
  }

  addTip() {
    this.addTipForm.markAllAsTouched();
    if (this.addTipForm.status == "VALID") {
      let amount = +(this.addTipForm.get("amountToBeAdded")?.value);
      this.orderDetailService.addTip(this.quotationDetails.id, amount).subscribe(res => {
        console.log(res);
        if (res.status == 1) {
          this.addTipForm.reset();
          this.modalService.dismissAll();
          this.successMsg = '';
          this.successMsg = res.message;
          $('#successToast').toast('show');
        } else {
          this.errorMsg = '';
          this.errorMsg = res.message;
          $('#errorToast').toast('show');
        }
      })
    }
  }

  closeSideMatDrawer() {
    this.sideMatDrawerSvc.closeQuotOrdDetailDrawer();
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getFormattedTime(timeString: any) {
    var hours = timeString?.split(':')[0];
    var minutes = timeString?.split(':')[1];
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
