import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderDetailsService } from '../services-and-models/order-details.service';

import { NgxSpinnerService } from "ngx-spinner";
import { geolocation, waypoint } from '../services-and-models/map-models';
import { environment } from 'src/environments/environment';
import { SideMatDrawerService } from 'src/app/shared/side-mat-drawer.service';

declare var $: any;

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css'],
  providers: [OrderDetailsService]
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  @Input('OrdId') OrdId!: number;
  orderId!: number;
  orderDetails!: any;

  showMap: boolean = false;
  pickuplocation: geolocation = new geolocation();
  droplocations: waypoint[] = [];
  lastdroplocation: geolocation = new geolocation();
  driverlocation: geolocation = new geolocation();
  successMsg!: string;
  errorMsg!: string;
  iconUrl: string = "../../../assets/img/location.png";

  baseImageUrl = environment.baseImageUrl;

  public renderOptions = {
    suppressMarkers: true,
  }

  // public markerOptions = {
  //   origin: {
  //     // infoWindow: 'This is origin.',
  //     // icon: 'your-icon-url',
  //     // draggable: true,
  //     //label: 'P',
  //   },
  //   destination: {
  //     //icon: 'your-icon-url',
  //     //label: 'D',
  //     //opacity: 0.8,
  //   },
  //   waypoints: [{
  //     label: ''
  //   }
  //     // {
  //     //   //icon: 'your-icon-url',
  //     //   label: 'B',
  //     //   //opacity: 0.8,
  //     // },
  //     // {
  //     //   icon: 'your-icon-url',
  //     //   label: 'marker label',
  //     //   opacity: 0.8,
  //     // },
  //   ],
  // }

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderDetailService: OrderDetailsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sideMatDrawerSvc: SideMatDrawerService
  ) { }

  ngOnInit(): void {
    this.spinner.show(undefined, {
      type: "ball-spin-clockwise-fade",
      color: '#0ea4e1'
    });
    this.orderId = this.OrdId;

    this.sideMatDrawerSvc.quotOrdId.subscribe(res => {
      this.spinner.show(undefined, {
        type: "ball-spin-clockwise-fade",
        color: '#0ea4e1'
      });

      this.orderId = res.OrderId;
      this.getOrderDetails();
    });
    // this.activatedRoute.params.subscribe(param => {
    //   this.orderId = param['id'];
    //   this.getOrderDetails();
    // })
    this.getOrderDetails();
  }

  private getOrderDetails() {
    this.orderDetailService.getOrderDetails(this.orderId).subscribe((returnData: any) => {
      console.log(returnData);
      this.orderDetails = returnData.data;

      this.pickuplocation.lat = +this.orderDetails.quotation.pickupaddress.latitude;
      this.pickuplocation.lng = +this.orderDetails.quotation.pickupaddress.longitude;
      // this.markerOptions.origin = { label: 'P' }
      // this.orderDetails.quotation.quotationdrops.forEach((drop: any) => {
      //   this.droplocations.push({
      //     location: {
      //       lat: +drop.dropupaddress.latitude,
      //       lng: +drop.dropupaddress.longitude
      //     },
      //     stopover: true
      //   });
      //   this.markerOptions.waypoints.push({label:'D'})
      // });
      // this.droplocations.push({
      //   location: {
      //     lat: +this.orderDetails.driver_lat,
      //     lng: +this.orderDetails.driver_lon
      //   },
      //   stopover: true
      // });
      // this.markerOptions.waypoints.push({label:'B'})
      // this.lastdroplocation.lat = +this.orderDetails.quotation.quotationdrops[this.orderDetails.quotation.quotationdrops.length - 1].dropupaddress.latitude;
      // this.lastdroplocation.lng = +this.orderDetails.quotation.quotationdrops[this.orderDetails.quotation.quotationdrops.length - 1].dropupaddress.longitude;
      // this.markerOptions.destination = { label: 'S' }
      //if (this.orderDetails.quotation?.quotationdrops?.length > 1) {
      for (let index = 0; index < this.orderDetails.quotation.quotationdrops?.length - 1; index++) {
        const drop = this.orderDetails.quotation.quotationdrops[index];
        this.droplocations.push({
          location: {
            lat: +drop.dropupaddress.latitude,
            lng: +drop.dropupaddress.longitude
          },
          stopover: true
        });
      }
      // this.droplocations.push({
      //   location: {
      //     lat: +this.orderDetails.driver_lat,
      //     lng: +this.orderDetails.driver_lon
      //   },
      //   stopover: false
      // });
      //} // else {
      //   this.droplocations.push({
      //     location: {
      //       lat: +this.orderDetails.quotation.quotationdrops[0].dropupaddress.latitude,
      //       lng: +this.orderDetails.quotation.quotationdrops[0].dropupaddress.longitude
      //     },
      //     stopover: true
      //   })
      // }
      let lastIndex = this.orderDetails.quotation.quotationdrops?.length - 1;
      //console.log(lastIndex)
      this.lastdroplocation.lat = +this.orderDetails.quotation.quotationdrops[lastIndex].dropupaddress.latitude;
      this.lastdroplocation.lng = +this.orderDetails.quotation.quotationdrops[lastIndex].dropupaddress.longitude;
      this.showMap = true;

      this.spinner.hide();
    });
  }

  updateDriverLocation() {
    // setTimeout(() => {
    //   this.droplocations.push({
    //     location: { lat: 1.300986, lng: 103.841187 },
    //     stopover: false
    //   })

    // }, 10000);
  }

  checkCountryCodeExist(driverNo: string): string {
    let mobileNo = "";
    if (driverNo && driverNo != '' || driverNo != null) {
      if (driverNo.charAt(0) != '+') {
        if (!driverNo.replace('+', '').startsWith('65')) {
          mobileNo = sessionStorage.getItem('country_code')?.toString().concat(driverNo)!
        }
      } else {
        mobileNo = driverNo;
      }
    }
    return mobileNo;
  }

  rebook(orderId: number) {
    this.router.navigate(['/admin/generate-order'], { queryParams: { id: orderId } });
  }

  directOrder(quotationId: number, spId: number) {
    let confirmation = confirm("Do you want to create direct order?");

    if (confirmation) {
      this.orderDetailService.directOrder(quotationId, spId).subscribe((returnData: any) => {
        console.log(returnData);
        if (returnData.status == 1) {
          this.successMsg = returnData.message
          $('#successToast').toast('show');
        } else {
          this.errorMsg = returnData.message
          $('#errorToast').toast('show');
        }
      })
    }
  }

  getServiceProviderRemark(spId: number, vehicle: string) {
    if (this.orderDetails.quotation.quotationsummary && this.orderDetails.quotation.quotationsummary.length > 0) {
      let spIdIndex = this.orderDetails.quotation.quotationsummary.findIndex((x: any) => x.service_provider_id == spId && x.vehicle.toLowerCase().includes(vehicle.toLowerCase()));
      if (spIdIndex != -1) {
        let remark = this.orderDetails.quotation.quotationsummary[spIdIndex].remark;
        if (remark && remark != null) {
          return remark
        } else {
          return ""
        }
      } else {
        if (this.orderDetails.order_status != "Void") {
          return ""
        } else {
          return ""
        }
      }
    } else {
      return "";
    }
  }

  getIcon(vehicle: string) {
    let vehicleName = vehicle?.toLowerCase();
    let iconClass: string = '';
    if (vehicleName?.includes("motorcycle") || vehicleName?.includes("bike")) {
      iconClass = "fas fa-motorcycle";
    } else if (vehicleName?.includes("car")) {
      iconClass = "fas fa-car-side";
    } else if (vehicleName?.includes("truck")) {
      iconClass = "fas fa-truck";
    }

    return iconClass;
  }

  closeSideMatDrawer() {
    this.sideMatDrawerSvc.closeQuotOrdDetailDrawer();
  }

  getFormattedTime(timeString: any) {
    var hours = timeString?.split(':')[0];
    var minutes = timeString?.split(':')[1];
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  ngOnDestroy(){
    console.log('destroyed')
  }
}
