import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminMasterPageComponent } from './masterpages/admin-master-page/admin-master-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatSidenavModule } from '@angular/material/sidenav';
import { QuotationDetailsComponent } from './admin/quotation-details/quotation-details.component';
//import { AgmCoreModule } from '@agm/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { OrderDetailsComponent } from './admin/order-details/order-details.component';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [
    AppComponent,
    AdminMasterPageComponent,
    QuotationDetailsComponent,    
    OrderDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    MatSidenavModule,
    NgxSpinnerModule,
    MatCheckboxModule,
    // AgmCoreModule.forRoot({
    //   apiKey:'AIzaSyA1Nxeiva9_o_Rkujr_A9jYEeSMT-uyj0s'
    // })
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDwOOUPTOwi9JCPAYVSFm8uhTYTSgXMtGA'
    }),
    AgmDirectionModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
